@import './mixin.scss';

$primaryColor: #2a324b;

$white: #fff;
$white-05: rgba(255, 255, 255, 0.5);
$white-005: rgba(255, 255, 255, 0.05);
$white-022: rgba(0, 0, 0, 0.22);
$white-011: rgba(0, 0, 0, 0.11);
$white-08: rgba(0, 0, 0, 0.08);

$black: #000;
$black-0: rgba(0, 0, 0, 0.07);
$black-12: rgba(0, 0, 0, 0.12);

$lightgrey: #f4f7fa;
$grey-dd: #ddd;
$grey-65: #656565;
$grey-7e: #7e868e;
$grey-eb: #ebebeb;
$grey-e9: #e9e9e9;
$grey-be: #bebebe;
$grey-f2: #f2f4f5;
$grey-d9: #d9dcde;
$grey-e1: #e1e1e2;
$grey-ec: #ecf2f8;
$grey-4e: #4e5468;
$grey-4a: #4a4a4a;
$grey-b3: #b3b3b3;
$grey-a0: #a0a0a0;
$grey-eb: #ebebeb;
$grey-d9: #d9d8d8;
$grey-ee: #eef3f9;
$grey-f7: #f7f7f7;
$grey-8c: #8c90a1;
$grey-ece: #ececec;
$grey-eef: #eef0f4;
$grey-a5: #a5a9b3;
$grey-a8: #a8adb3;
$grey-e3: #e3e6ed;
$dark-grey: #577194;

$dark-blue: #464d69;
$dark-navy-blue: #000014;
$blue-2a: #2a324b;
$blue-b9: #b9c1d7;
$blue-5c: #5c7191;
$blue-38: #3897f0;

$green-53: #53c3bd;
$grey-ea: #eaf8f7;
$grey-e7: #e7e8e7;

$red: #f44336;
$red-d7: #d7140d;
$red-ff: #ffebea;

$green-03: #038c25;
$green-d4: #d4f7dd;
$green-f2: #f2fffe;
$green-a0: #a0e3e0;

$blue-17: #1777e2;
$blue-dc: #dcedff;

$purple-03: #032dae;
$purple-d0: #d0dbfc;

$green-00: #0097b4;
$green-d1: #d1fdfe;

$yellow-c0: #c09105;
$yellow-ff: #fff7d6;

$lightskyblue-d1: #d1fdfe;
$lightskyblue-00: #0097b4;
$skyblue-ea: #eafffe;
$skyblue-bc: #bce8e6;

$purple: #9895e0;
$purple-ed: #edecff;
$purple-98: #9895e0;
$purple-e8: #e8edff;
$purple-c7: #c7cee9;

$pink-f7: #f76ca7;
$pink-ff: #ffedf5;
$pink-fe: #feefff;
$pink-d0: #d05bd8;

$green-39: #39cd85;
$green-e7: #e7fff3;
$green-39: #39cd85;
$darkpink-d0: #d05bd8;
$red-fd: #fd6363;
$red-ff: #ffebeb;

$blue-4f: #4f67db;
$blue-e6: #e6ebff;

$orange-e5: #e5895d;
$orange-fff: #fff0e8;
$orange-e5: #e5895d;
$orange-ff: #ff8e66;

$yellow-cc: #ccc02f;
$yellow-ff: #fffde3;
$yellow-cc: #ccc02f;

$dark: #000000;
$dark-navy-blue: #000014;
$light-blue-grey-two: #dce5f0;
$ice-blue: #f4f9ff;
$battleship-grey-two: #697088;
$palegrey: #eeeff0;
$black-two: #222222;
$slate-grey-two: #545860;
$pale-grey-four: #f2f3f5;
$slate-grey-three: #686c70;
$light-grey-two: #e7e8e7;
$light-grey-ec: #ecedf0;
$windows-blue: #467bc9;
$true-green: #059d08;
$pale-grey-five: #dee2ea;
$dark-indigo: #091f38;
$pale-grey-six: #e1e8f2;
$slate-grey-five: #545b66;
$charcoal-grey-three: #323a42;
$tomato-two: #ee3d3d;
$bedge-red: #e6535d;
$warning-color: #ff0000;
$pastel-red: #dc5959;
$cloudy-blue: #c5ccda;
$black-three: #262626;
$pale-grey-eight: #f2f5f7;
$dark-black: #141823;
$slate-grey-six: #5d6770;
$cool-grey-two: #9ba1ad;
$purple-grey: #8e8d95;
$salmon: #ff6e6e;
$light-grey-three: #dcdcdc;
$white-three: #dddddd;
$white-four: #fbfbfb;
$white-five: #f4f4f4;
$charcoal-grey-four: #404041;
$pale-grey-ten: #dadee6;
$pale-grey-eleven: #e9ebed;
$very-light-pink: #fffefe;
$windows-blue: #3470be;
$pale-grey-sixteen: #e8eaf0;
$azul: #1777e2;
$pink-red: #f8063f;
$teal-green: #26ad70;
$vermillion: #ee1b1b;
$pinkish-grey: #cacaca;
$pale-grey-eighteen: #eef3f8;
$pinkish-grey: #cacaca;

$directory-max: 800px;
$directory-max-inner: 700px;

// Breakpoint

@mixin bp($point) {
  $xxs-min: '(max-width: 480px)';
  $xs-min: '(max-width: 576px)';
  $sm-min: '(max-width: 767px)';
  $md-min: '(max-width: 991px)';
  $lg-min: '(max-width: 1200px)';
  $xl-min: '(max-width: 1366px)';
  $xxl-min: '(max-width: 1600px)';
  @if $point == xxs-min {
    @media #{$xxs-min} {
      @content;
    }
  } @else if $point == xs-min {
    @media #{$xs-min} {
      @content;
    }
  } @else if $point == sm-min {
    @media #{$sm-min} {
      @content;
    }
  } @else if $point == md-min {
    @media #{$md-min} {
      @content;
    }
  } @else if $point == lg-min {
    @media #{$lg-min} {
      @content;
    }
  } @else if $point == xl-min {
    @media #{$xl-min} {
      @content;
    }
  } @else if $point == xxl-min {
    @media #{$xxl-min} {
      @content;
    }
  }
}

//media query
// ********** media query  variables **********
$DesktopXL: 'only screen and (max-width : 1919px)';
$DesktopL: 'only screen and (max-width : 1660px)';
$DesktopM: 'only screen and (max-width : 1440px)';
$Desktop: 'only screen and (max-width : 1366px)';
$Laptop: 'only screen and (max-width : 1199px)';
$Ipad: 'only screen and (max-width : 1024px)';
$IpadS: 'only screen and (max-width: 991px)';
$PhoneL: 'only screen and (max-width: 767px)';
$PhoneS: 'only screen and (max-width: 575px)';
