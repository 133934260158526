@import '../../variables.scss';

.TopOpportunityStylesPanel {
    border-radius: 4px;
    color: $primaryColor;
    // border: solid 1px $pale-grey-five;
    background-color: $white;
    position: sticky;
    position: -webkit-sticky;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.104506);
    -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.104506);
    -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.104506);

    .panel {
        &__header {
            //   border-bottom: solid 1px $pale-grey-five;
            padding: 15px;
            padding-bottom: 0px;

            @include d-flex {
                align-items: checkAlign(c);
            }

            &__title {
                font-weight: 500;
                margin: 0;
                padding: 0;
                font-size: 17px;
                font-family: Graphik-medium;
            }

            &__see-more-link {
                flex: 1;
                text-align: right;
                font-family: Graphik-regular;
                text-decoration: underline;
                text-transform: capitalize;
                color: $green-53;
                font-size: 15px;
            }
        }

        &__body {
            &__list {
                list-style: none;
                margin: 0;
                padding: 0;

                &-item {
                    display: block;
                    @include space(padding, 15px, 15px, 0, 15px);

                    &:hover {
                        >.item-name {
                            color: $green-53;
                        }
                    }

                    &:last-child {
                        padding-bottom: 15px;

                        hr {
                            display: none;
                        }
                    }

                    hr {
                        background-color: $pale-grey-five;
                        border: none;
                        height: 1px;
                    }

                    .item-name {
                        font-size: 15px;
                        font-family: Graphik-regular;
                        margin-bottom: 10px;
                        // text-transform: uppercase;
                        color: $primaryColor;
                        line-height: 17px;
                        margin: 0;
                    }

                    .item-date {
                        margin: 3px 0px 3px 0px;
                        color: $cool-grey-two;
                        font-size: 13px;
                        font-family: Graphik-regular;

                        @include d-flex {
                            align-items: checkAlign(c);
                        }

                        svg {
                            margin-right: 10px;
                            fill: $cool-grey-two;
                        }
                    }
                }
            }
        }
    }
}