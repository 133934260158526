@import '../../variables.scss';

.ModalSubscriptionPrompt {
    .ant-modal-content {
        border-radius: 18px;

        .modalpopup {
            text-align: center;

            .modalimage {
                padding: 18px 0;

                img {
                    height: 45px;
                }
            }

            h2 {
                color: $blue-2a;
                font-weight: 300;
                font-size: 30px;
                line-height: 36px;
            }
        }

        .ant-modal-footer {
            padding: 0;
            border-top: 1px solid $grey-7e;
            text-align: center;
            display: flex;

            .ant-btn {
                padding: 15px 0 18px;
                width: 100%;
                color: $blue-2a;
                border: none;
                background-color: transparent;
                font-size: 16px !important;
                line-height: 23px;
                box-shadow: none;
                text-shadow: none !important;
                &:first-child {
                    border-right: 1px solid $grey-7e;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                }
            }
        }
    }
}