.chromeModal {
  position: fixed;
  right: 12px;
  bottom: 12px;

  width: 364px;

  z-index: 100;
  display: flex;
  padding: 12px;
  flex-direction: column;
  gap: 12px;
  border-radius: 16px;
  background: var(--Backgrounds-Primary, #fff);
  box-shadow: 0px 15px 35px 10px rgba(17, 24, 38, 0.25),
    0px 5px 15px 3px rgba(0, 0, 0, 0.08);

  .extensionImageContainer {
    position: relative;
    width: 100%;

    .extensionImage {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .extensionImageClose {
      position: absolute;
      top: 10px;
      right: 10px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .chromeModalHeader {
    display: flex;
    width: 100%;
    flex-direction: column;

    .header {
      color: var(--Text, #2a324b);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .content {
      color: var(--Main, #464d69);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .addButton {
    border-radius: 8px;
    background: var(--Secondary, #53c3bd);
    color: var(--White, var(--Backgrounds-Primary, #fff));
  }
}
