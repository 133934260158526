@import "../../../../variables.scss";
.SearchDropdownStyle {
  .searchDropdownRecent {
    .searchDropdownRecentTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 21px;
      border-bottom: 1px solid $palegrey;
      strong {
        @include text($dark, 18px, 500);
      }
    }
    .searchDropdownRecentBox {
      @include space(padding, 0, 15px, 0, 15px);
      .searchDropdownRecentItem {
        display: flex;
        align-items: center;
      }
    }
  }
  .searchDropdown {
    .searchDropdownBox {
      @include space(padding, 0, 15px, 0, 15px);
    }
    .viewmoreResult {
      @include space(padding, 13px, 0, 13px, 0);
      text-align: center;
      border-top: 1px solid $palegrey;
    }
    .user-text {
      @include space(margin, 5px, 0, 0, 0);
    }
  }
}
.certain-category-search-dropdown {
  .ant-select-item {
    cursor: inherit;
    padding: 0;
    &.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: inherit;
    }
  }
}
