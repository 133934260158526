@import '../../variables.scss';

.completeAccountAlertStyles {
  background-color: $primaryColor;
  // border: 1px solid $pale-grey-five;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.104506);
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.104506);
  -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.104506);
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 10px;
  color: $white;

  h4 {
    margin-bottom: 10px;
    padding: 0;
    font-weight: 500;
    font-size: 17px;
    font-family: Graphik-medium;
    color: $white;
  }

  p {
    font-size: 15px;
    font-family: Graphik-regular;
    margin-bottom: 10px;
    color: $white;
  }

  .ant-progress {
    .ant-progress-text {
      color: $white;
    }

    .ant-progress-bg {
      background: $white;

      &:before {
        background: #04040469;
      }
    }
  }

  ul.account-actions {
    list-style: none;
    margin: 0;
    margin-top: 10px;
    padding: 0;

    li {
      margin-bottom: 7px;

      &:last-child {
        margin: 0;
      }

      @include d-flex {
        align-items: checkAlign(c);
      }

      font-size: 15px;

      svg {
        margin-right: 6px;
        width: 19px;
        height: 19px;
        fill: $white;
      }

      &.active span {
        text-decoration: line-through;
      }

      &.inactive svg {
        fill: #ffffff82;
      }

      a {
        color: $white;

        @include d-flex {
          align-items: checkAlign(c);
        }

        &:hover>span {
          color: #ffffff94;
        }
      }
    }
  }
}