@import './variables.scss';

// Font-family style
@font-face {
  font-family: 'Graphik-regular';
  src: local('Graphik-regular'),
    url(./fonts/GraphikRegular.ttf) format('truetype');
}

@font-face {
  font-family: 'Graphik-medium';
  src: local('Graphik-medium'),
    url(./fonts/GraphikMedium.ttf) format('truetype');
}

// Common style
body {
  margin: 0;
  padding: 0;
  background: $lightgrey;
  font-family: Graphik-regular;
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::selection {
  color: $white;
  //   background: $selection !important;
}

#modal_root {
  position: relative;
  z-index: 999;
}

// Scrollbar design

::-webkit-scrollbar-thumb {
  background-color: $grey-be;
  border-radius: 10px;
}

::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  width: 16px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 5px solid transparent;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px rgba(255, 255, 255, 0.2);
}

input:-internal-autofill-selected {
  background-color: $white !important;
}

.fullheight {
  height: 100%;
}

.fullWidth {
  width: 100%;
}

::placeholder,
:-ms-input-placeholder,
::-webkit-input-placeholder {
  color: blue;
}

.ant-btn {
  &.noPad {
    padding: 0;
  }
}

.bg-light-grey {
  background-color: $lightgrey;
}

.p15 {
  padding: 30px 15px;
}

.w100 {
  width: 100%;
}

p {
  color: $grey-4a;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 0;

  span {
    display: block;
    font-family: Graphik-regular;
  }
}

.ant-divider-horizontal {
  margin: 20px 0;
}

.borderbox {
  background-color: $white;
  border: 1px solid $grey-e1;
  border-radius: 4px;
}

.topsectionspacing {
  padding: 20px 16px;

  @include bp(sm-min) {
    padding: 18px 12px;
  }
}

.borderbottom {
  margin-bottom: 10px;
  padding-bottom: 12px;
  border-bottom: 1px solid $grey-eb;
}

.greybgblock {
  padding: 10px;
  border: 1px solid $grey-ece;
  background-color: $grey-f7;
}

.mainLayout {
  height: 100vh;
}

.site-layout-background {
  padding: 15px;
}

.main-site-content {
  padding-bottom: 30px;
  width: 100%;

  &.with-right-sidebar,
  &.with-left-sidebar {
    width: 550px;

    @include bp(md-min) {
      width: 500px;
    }

    @include bp(xxs-min) {
      width: 100%;
    }
  }
}

.site-layout {
  // position: relative;
  width: 1127px;
  padding-top: 64px;
  margin: 0 auto;
  flex-direction: row;

  @include bp(xxs-min) {
    padding-top: 70px;
    width: 100%;
  }

  @include bp(lg-min) {
    width: 100%;
    // margin: 10px;
  }
}

h1,
h2,
h3,
h4 {
  color: $blue-2a;
  font-family: Graphik-medium;
}

h1 {
  margin: 0 10px 10px 0;
  font-size: 22px;
  line-height: 22px;

  @include bp(md-min) {
    font-size: 20px;
  }
}

h2 {
  margin-bottom: 15px;
  font-size: 19px;
  line-height: 33px;
}

h3 {
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 18px;
}

h4 {
  font-size: 15px;
  line-height: 19px;
}

ul {
  margin-bottom: 0;

  li {
    // list-style-type: none;
    transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
  }
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.errormsg {
  color: $red;
  font-size: 16px;

  img {
    margin-right: 10px;
  }
}

.ant-layout {
  background: transparent;
}

.flexblock {
  display: flex;
  align-items: center;
  justify-content: center;

  @include bp(xxs-min) {
    flex-wrap: wrap;
  }
}

.flexendbox {
  display: flex;
  justify-content: flex-end;

  @include bp(xs-min) {
    justify-content: center;
  }
}

.flexstartbox {
  display: flex;
  justify-content: flex-start;
  @include bp(xs-min) {
    justify-content: center;
  }
}

.justifyspacebetween {
  justify-content: space-between;
}

.smalltext {
  color: $grey-8c;
  font-size: 13px;
}

.ant-btn {
  &.noTheme {
    height: auto;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 18px;
  }
}

.btngorup {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-filter {
  padding-bottom: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.zup {
  z-index: 2;
}

.ant-space {
  .ant-space-item {
    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.ant-space-align-center {
  @include bp(xxs-min) {
    flex-wrap: wrap;
  }
}

.title,
#opportunityName {
  font-family: 'Graphik-medium';
  @include bp(sm-min) {
    font-size: 22px;
  }

  @include bp(xxs-min) {
    font-size: 20px;
  }

  &.borderbottom {
    margin-bottom: 10px;
    padding-bottom: 20px;
  }
}

.title {
  margin-bottom: 0;
  line-height: 25px;

  @include bp(sm-min) {
    line-height: 30px;
  }
}

.ant-picker-cell {
  &.ant-picker-cell-disabled {
    &:before {
      background-color: $white;
    }
  }

  &.ant-picker-cell-selected {
    .ant-picker-cell-inner {
      color: $white;
      background: $green-53;
      border-radius: 50%;
    }
  }

  .ant-picker-cell-inner {
    min-width: 36px;
    height: 36px;
    line-height: 36px;
  }
}

// Filter section style
.filterblock {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  .ant-space-align-center {
    @include bp(lg-min) {
      flex-wrap: wrap;
    }
  }

  .ant-input-affix-wrapper {
    max-width: 206px;
    padding: 4px 10px;

    input[type='text'] {
      padding-left: 6px !important;
      color: $grey-65;
      font-size: 13px;
      line-height: 19px;
      font-family: Graphik-regular;
    }
  }

  .filterbtns {
    @include bp(md-min) {
      margin: 10px 0 0;
    }

    ul {
      display: flex;
      padding: 0;

      @include bp(md-min) {
        flex-wrap: wrap;
      }

      li {
        height: 32px;
        color: $blue-2a;
        margin-right: 6px;
        list-style-type: none;

        @include bp(md-min) {
          margin: 0 6px 10px 0;
        }

        &:last-child {
          margin-right: 0;
        }

        .ant-btn {
          padding: 0 16px;
          border: none;
          background-color: $grey-f2;
          border-radius: 4px;
          font-size: 13px;
          line-height: 32px;
          font-family: Graphik-regular;

          &.active {
            color: $white;
            background-color: $dark-grey;
          }
        }

        .ant-select {
          &.ant-select-multiple {
            .ant-select-selector {
              padding: 0px 16px;
              background-color: $grey-f2;
              border: none;

              .ant-select-selection-search {
                width: auto !important;
                margin: 0;
              }
            }

            .ant-select-selection-placeholder {
              left: 16px;
              color: $blue-2a;
              font-size: 13px;
            }

            .ant-select-selection-item {
              background-color: $white;
            }
          }
        }
      }
    }
  }

  .ant-select {
    &.ant-select-single {
      .ant-select-selector {
        height: 32px;
        padding: 0 12px;
        border-color: $grey-f2;
        background-color: $grey-f2;

        &:hover {
          border-color: $grey-f2;
        }

        .ant-select-selection-item {
          color: $blue-2a;
          font-size: 13px;
          font-family: Graphik-regular;
          line-height: 31px;
        }
      }
    }

    &.ant-select-focused {
      border-color: $grey-f2;

      &:focus,
      &:hover {
        border-color: $grey-f2;
      }
    }

    .ant-select-item {
      .ant-select-item-option-content {
        display: flex;
        justify-content: space-between;
        color: $grey-4a;
        font-size: 14px;
        font-family: 'Graphik-regular';
        line-height: 26px;
      }
    }
  }
}

// Button style
.ant-btn {
  padding: 5px 26px;
  border-radius: 4px;
  font-size: 13px;
  font-family: Graphik-medium;
  text-shadow: none !important;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;

  @include bp(lg-min) {
    padding: 5px 20px;
  }

  &.whitebtn {
    border: 1px solid $grey-e1;
    background-color: $white;
    color: $grey-65;
  }

  &.greenbtn {
    background-color: $green-53;
    border: 1px solid $green-53;
    color: $white;
  }

  &.secondarybtn {
    color: $dark-blue;
    background-color: transparent;
    border: 1px solid $dark-blue;

    &:hover {
      color: $white;
      background-color: $dark-blue;
    }
  }

  &.ant-btn-lg {
    font-size: 18px;
  }
}

// Authentication page style
.authform {
  width: 100%;
  max-width: 520px;
  padding: 45px 67px;
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0 4px 8px -2px $white-011;

  @include bp(xxs-min) {
    padding: 30px 15px;
  }

  .websitelogo {
    margin: 0 0 30px;

    img {
      margin: 0 auto;
    }
  }

  h1 {
    margin: 0 0 7px;
    color: $blue-2a;
    font-size: 30px;
    font-family: Graphik-regular;
    line-height: initial;
    letter-spacing: 0.5px;

    @include bp(xxs-min) {
      font-size: 26px;
    }
  }

  .link {
    span {
      font-family: Graphik-medium;
    }
  }

  .otherlinkblock {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-top: 10px;

    &::before {
      position: absolute;
      top: -10px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 100px;
      height: 1px;
      background-color: $dark-blue;
      text-align: center;
      content: '';
    }
  }
}

.login-form-forgot {
  margin: 21px 0 11px;
}

.authtext {
  margin: 25px 0;
  color: $grey-65;
}

.authinfo {
  margin-top: 0;
}

.otpInput {
  margin-bottom: 20px;
}

// Input field style
input[type='text'],
input[type='password'],
input[type='email'],
input[type='number'],
input[type='date'],
input[type='range'],
.ant-input,
textarea,
select {
  padding-left: 12px;
  padding-right: 12px;
  font-family: Graphik-medium;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  resize: none;

  &:focus {
    border-color: $grey-dd;
    box-shadow: none;
  }
}

.ant-input[disabled] {
  background-color: $grey-ece;
  color: $dark-navy-blue;
}

.ant-picker {
  padding: 8px 12px;

  .ant-picker-input {
    > input {
      font-family: Graphik-medium;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.ant-select {
  &.ant-select-single {
    .ant-select-selector {
      // padding: 0 30px 0 12px;
      .ant-select-selection-search {
        left: 12px;
      }

      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        font-family: Graphik-medium;
      }
    }
  }

  &.ant-select-focused {
    .ant-select-selector {
      border-color: $grey-dd !important;
      box-shadow: none !important;
    }
  }
}

.ant-select-multiple {
  .ant-select-selection-search {
    margin-inline-start: 0;
  }
}

.tabFilterDropdown {
  .filtermenu {
    .input {
      padding: 12px;
    }

    @include size($width: 241px);
    @include space(padding, 0, 0, 0, 0);
    border-radius: 6px;
    box-shadow: 0 4px 5px 0 rgba($color: $dark, $alpha: 0.22);
    background-color: $white;

    .ant-radio-group {
      @include d-flex {
        flex-direction: column;
      }

      .ant-radio-wrapper {
        flex-direction: row-reverse;
        line-height: 1;
        border-bottom: 1px solid $palegrey;
        @include space(padding, 9px, 18px, 9px, 20px);
        @include space(margin, 0, 0, 0, 0);

        @include d-flex {
          align-items: checkAlign(c);
          justify-content: getJustify(sb);
        }

        .ant-radio {
          .ant-radio-inner {
            @include size(21px, 21px);

            &::after {
              @include size(11px, 11px);
              top: 4px;
              left: 4px;
            }
          }

          + span {
            @include space(padding, 0, 8px, 0, 0);
            color: $dark-navy-blue;
          }

          &-checked {
            + span {
              color: $primaryColor;
            }
          }
        }
      }
    }

    .ant-checkbox-group {
      @include d-flex {
        flex-direction: column;
      }

      .ant-checkbox-wrapper {
        flex-direction: row-reverse;
        line-height: 1;
        border-bottom: 1px solid $palegrey;
        @include space(padding, 9px, 12px, 9px, 12px);
        @include space(margin, 0, 0, 0, 0);

        @include d-flex {
          align-items: checkAlign(c);
          justify-content: getJustify(sb);
        }
      }
    }

    .filterAction {
      border-top: 1px solid #eee;
      @include space(padding, 14px, 20px, 15px, 20px);

      @include d-flex {
        align-items: checkAlign(c);
        justify-content: getJustify(sb);
      }

      .ant-btn {
        &:last-child {
          margin-left: auto;
        }
      }
    }
  }
}

.ant-dropdown-mask {
  background-color: rgba(9, 31, 56, 0.32);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
}

.ant-select-arrow {
  width: 16px;
  height: 16px;
  margin-top: -8px;

  svg {
    width: 100%;
    height: 16px;
  }
}

.ant-input-affix-wrapper {
  padding: 8px 9px;
}

// Form design style
.ant-form {
  .ant-form-item {
    margin-bottom: 12px;
  }
}

.ant-form-item {
  .ant-form-item-label {
    display: block;
    width: 100%;
    height: auto;
    padding-bottom: 0;
    text-align: left;

    > label {
      height: auto;
      font-family: 'Graphik-regular';
      &:before,
      &:after {
        display: none !important;
      }
    }
  }

  .ant-input-affix-wrapper {
    // padding-left: 0;
    // padding-right: 0;
    .ant-input-suffix {
      margin-right: 12px;

      svg {
        font-size: 19px;
      }
    }

    &.ant-input-affix-wrapper-focused {
      box-shadow: none;
    }
  }

  .formsubmitbtn {
    margin-top: 18px;
    font-size: 18px;
  }

  .ant-checkbox-wrapper {
    span {
      font-size: 14px;
      color: $grey-65;

      .ant-checkbox-inner {
        border: 1px solid $grey-7e;
      }
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: $blue-2a;
        border-color: $blue-2a;
      }
    }
  }
}

.ant-input-affix-wrapper {
  input[type='text'] {
    padding-left: 6px !important;
    font-family: Graphik-regular;
  }

  &:hover {
    border-color: $grey-dd;
    box-shadow: none;
  }
}

.ant-form-item-explain-error {
  div {
    text-align: left;
  }
}

.ant-form-item-control-input-content {
  color: $grey-65;
  font-size: 16px;
  font-family: Graphik-regular;

  .anticon-calendar {
    svg {
      font-size: 16px;
      color: $grey-7e;
    }
  }
}

// Link style
.link {
  padding: 0 5px;
  color: $blue-2a;
  font-size: 16px;
  font-family: Graphik-medium;
  word-break: break-all;

  &:hover {
    color: $green-53;
  }
}

.documentlink {
  color: $green-53;
  font-size: 13px;
  font-family: Graphik-medium;
  line-height: 20px;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }

  @include bp(xxs-min) {
    word-break: break-all;
  }
}

// Modal design style
.ant-modal {
  z-index: 999;

  .ant-modal-content {
    .ant-modal-close {
      .ant-modal-close-x {
        height: 75px;
        line-height: 75px;

        .ant-modal-close-icon {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: $grey-f2;

          svg {
            width: 30px;
            height: 30px;
            font-size: 14px;
          }
        }
      }
    }

    .ant-modal-header {
      padding: 21px 50px 20px;
      border-bottom: 1px solid $grey-eb;
      text-align: center;

      @include bp(sm-min) {
        padding: 20px;
      }

      .ant-modal-title {
        color: $blue-2a;
        font-size: 22px;
        font-family: Graphik-medium;
        line-height: 33px;
        text-transform: capitalize;

        @include bp(xs-min) {
          font-size: 18px;
        }
      }
    }

    .ant-modal-body {
      padding: 30px 45px 20px;

      @include bp(sm-min) {
        padding: 30px 20px 20px;
      }

      p {
        span {
          display: inline-block;
          padding: 0 5px;
          font-family: Graphik-medium;
        }
      }

      .teamsmainblock {
        margin-top: 0;
        padding-top: 0;
        border-top: none;

        .memberblock {
          margin: 10px 0;

          .memmberprofile {
            min-width: 45px;
            width: 45px;
            height: 45px;

            p {
              &.fakeImage {
                line-height: 45px;
                font-size: 15px;
              }
            }
          }
        }
      }

      .ant-form {
        .ant-form-item {
          // margin-bottom: 12px;
          &.criteria {
            .ant-form-item-control-input-content {
              display: flex;
              justify-content: space-between;
              padding: 10px 14px 8px;
              border: 1px solid $grey-dd;
              border-radius: 4px;

              p {
                margin-bottom: 0;
                font-size: 14px;
                font-family: Graphik-medium;
              }
            }
          }

          &.solicitation {
            padding: 0 14px 0;
            border: 1px solid $grey-ece;
            background-color: $grey-f7;
            border-radius: 4px;

            .documentlink {
              font-size: 15px;
            }
          }
        }
      }

      .ant-collapse {
        border: 1px solid $grey-ece;

        > .ant-collapse-item {
          border-bottom: 1px solid $grey-ece;

          .ant-collapse-content {
            border-top: none;
            background-color: $grey-f7;

            > .ant-collapse-content-box {
              padding: 0 15px 16px 40px;

              .documentlink {
                display: flex;
                width: 100%;
                margin-bottom: 10px;
                word-break: break-all;
              }
            }
          }
        }
      }

      .btnlink {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        border: none;
        color: $green-53;

        span {
          padding: 0 15px;
          color: $green-53;
          font-size: 14px;
          line-height: 17px;
          font-family: Graphik-medium;
        }
      }
    }

    .ant-modal-footer {
      // padding: 0 45px 30px;
      // border-top: none;
      // @include bp(sm-min) {
      //   padding: 0 20px 30px;
      // }

      .ant-btn {
        height: auto;
        padding: 12px 25px 11px;
        font-size: 18px;
      }
    }
  }
}

.ant-modal {
  &.modalwidthstyles {
    width: 602px !important;
  }

  &.deletemodalwidthstyles {
    width: 350px !important;

    h3 {
      margin-top: 10px;
    }
  }

  &.govdetailmodal {
    width: 790px !important;

    .ant-modal-content {
      .ant-modal-body {
        padding: 0;

        .supportdetailblock {
          padding: 20px;
        }
      }
    }
  }

  &.changeprofilemodal {
    width: 400px !important;

    .ant-modal-content {
      .ant-modal-body {
        padding: 30px 40px 40px;

        .myprofilelinks {
          .ant-btn {
            display: flex;
            margin-bottom: 20px;
            color: $blue-2a;
            font-size: 16px;

            svg {
              margin-right: 8px;
              font-size: 12px;
            }
          }
        }

        .myprofileimg {
          height: 124px;
          width: 124px;
          border-radius: 100%;
          border: 1px solid $grey-e9;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

// Commentupload style
.documentblock {
  .attachmentbox {
    display: flex;

    .alltachment {
      width: 136px;
      height: 106px;
      border: 1px solid $dark-blue;
      border-radius: 4px;
      margin: 0 12px 12px 0;
      padding: 10px;
      font-size: 12px;
      position: relative;
      background-size: cover;

      .top {
        position: absolute;
        top: 10px;
        left: 10px;
        right: 25px;
        overflow: hidden;

        p {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin: 0;
          color: $white;
          font-weight: 500;
        }
      }

      .layer {
        background-color: rgba(70, 77, 105, 0.57);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .footer {
        color: $white;
        position: absolute;
        padding: 0 10px;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          color: $white;
        }

        .downloadIcon {
          cursor: pointer;
        }
      }

      .delete {
        position: absolute;
        top: 4px;
        right: 6px;
        color: $white;

        svg {
          font-size: 13px;
        }
      }
    }
  }

  .commentuploadblock {
    position: relative;
    width: 100%;
    padding-top: 40px;

    .ant-upload-select {
      position: absolute;
      right: 216px;
      top: 8px;

      @include bp(lg-min) {
        right: 188px;
      }

      @include bp(xxs-min) {
        right: 158px;
      }
    }

    .ant-upload-list {
      position: relative;
      top: 0;
      left: 0;
      display: flex;
      flex-wrap: wrap;

      .ant-upload-list-text-container {
        margin: 0 10px 5px 0;
        padding: 5px;
        border: 1px solid $grey-e9;
        border-radius: 3px;

        .ant-upload-list-item {
          height: auto;
          margin-top: 0;

          .ant-upload-list-item-name {
            padding-right: 25px;
            color: $blue-2a;
            font-size: 12px;
            word-break: break-all;
            white-space: pre-wrap;
          }

          .ant-upload-list-item-card-actions {
            .ant-btn {
              right: 0;
            }
          }
        }
      }
    }

    .ant-btn {
      position: absolute;
      top: 0;
      right: 108px;

      @include bp(lg-min) {
        right: 95px;
      }

      @include bp(xxs-min) {
        padding: 5px 14px;
        right: 80px;
      }

      &.greenbtn {
        right: 0;
      }
    }
  }
}

// Detail modal style
.supportdetailblock {
  // padding: 30px;
  background-color: $blue-2a;

  .ant-btn {
    // color: $white;
    // border: 1px solid $grey-4e;
    // background-color: $white-005;
    font-family: 'Graphik-regular';

    &.addToBidboardBtn {
      position: absolute;
      left: 20px;

      @include bp(xs-min) {
        position: relative;
        left: 0;
      }
    }
  }
}

.informationblock {
  padding: 24px 30px 30px;

  h2 {
    margin-bottom: 35px;
    color: $blue-2a;
    font-size: 16px;
    text-align: center;
  }

  .ant-tabs {
    .ant-tabs-tabpane {
      max-height: 500px;
      overflow: auto;
      padding: 26px 20px;
      background-color: $lightgrey;

      p {
        margin-bottom: 30px;
        color: $dark-blue;
        font-size: 14px;
        font-family: 'Graphik-Medium';

        span {
          display: block;
          color: $grey-65;
          font-size: 13px;
          line-height: 19px;
          margin: 0;
          padding: 0;
          font-family: 'Graphik-Regular';

          &.detailpopover {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            line-height: 24px;

            .ant-btn {
              .noTheme {
                height: 20px;
              }
            }
          }
        }

        a {
          display: block;
          margin-top: 10px;
          color: $green-53;
          font-family: 'Graphik-Regular';
        }

        svg {
          margin-right: 10px;
        }
      }
    }
  }
}

// Column select box style

.columnblock {
  .ant-select {
    &.ant-select-single {
      .ant-select-selector {
        height: 32px;
        padding-left: 15px;

        .ant-select-selection-placeholder {
          font-size: 13px;
          font-family: Graphik-regular;
          line-height: 32px;
        }
      }
    }
  }
}

// Table design style
.ant-table {
  margin-bottom: 12px;

  table {
    .ant-table-thead {
      tr {
        th {
          color: $blue-2a;
          background: $grey-ec;
          font-size: 12px;
          line-height: 18px;
          font-family: Graphik-medium;
          text-transform: uppercase;
        }
      }
    }

    .ant-table-tbody {
      tr {
        td {
          color: $grey-65;
          font-size: 14px;
          font-family: Graphik-regular;
          line-height: 20px;

          .ant-checkbox {
            .ant-checkbox-inner {
              border: 1px solid $grey-a8;
            }
          }
        }
      }
    }
  }
}

.actionbtn {
  text-align: right;

  .anticon {
    margin: 0 4px;

    svg {
      font-size: 18px;
    }
  }
}

.followupdateblock {
  display: flex;
  align-items: center;

  .redcolor {
    color: $red;
  }
}

// Tabs Design
.ant-tabs {
  width: 100%;

  .ant-tabs-nav {
    margin: 0;

    .ant-tabs-nav-list {
      @include bp(xs-min) {
        width: 100%;
        flex-wrap: wrap;
        text-align: center;
      }

      .ant-tabs-tab {
        margin: 0;
        padding: 0;

        @include bp(xs-min) {
          display: block;
          width: 100%;
          padding: 2px 10px;
          align-items: center;
          justify-items: center;
          text-align: center;
        }

        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: $green-53;
          }
        }

        .ant-tabs-tab-btn {
          padding: 10px 16px;
          color: $grey-65;
          font-size: 16px;
          font-family: Graphik-medium;
          line-height: 17px;

          @include bp(xs-min) {
            width: 100%;
          }
        }
      }
    }

    .ant-tabs-ink-bar {
      background: $green-53;
    }
  }

  .ant-tabs-content-holder {
    .ant-tabs-content {
      background-color: $white;
    }
  }
}

// Popup design style
.ant-popover {
  z-index: 99;

  .ant-popover-inner-content {
    padding: 0;

    ul {
      padding: 0;

      li {
        list-style-type: none;
        border-bottom: 1px solid $grey-eb;

        &:last-child {
          border-bottom: none;
        }

        .ant-btn {
          display: flex;
          align-items: center;
          height: auto;
          padding: 14px 24px 13px;
          color: $blue-2a;
          font-size: 14px;
          font-family: Graphik-medium;

          svg {
            margin-right: 14px;
          }
        }
      }
    }

    .mapTooltip {
      h4,
      p {
        margin-bottom: 0;
        color: $dark-blue;
      }

      h4 {
        font-size: 13px;
      }

      p {
        font-size: 11px;
        line-height: 17px;
      }
    }
  }
}

// Priority box design
.priorityblock {
  width: fit-content;
  padding: 6px 10px 5px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;

  &.redbox {
    background-color: $red-ff;
    color: $red-d7;
  }

  &.greenbox {
    background-color: $green-d4;
    color: $green-03;
  }

  &.bluebox {
    background-color: $blue-dc;
    color: $blue-17;
  }

  &.purplebox {
    background-color: $purple-d0;
    color: $purple-03;
  }

  &.lightgreenbox {
    background-color: $green-d1;
    color: $green-00;
  }

  &.yellowbox {
    background-color: $yellow-ff;
    color: $yellow-c0;
  }

  &.yellowbox {
    background-color: $yellow-ff;
    color: $yellow-c0;
  }

  &.lightskybluebox {
    background-color: $lightskyblue-d1;
    color: $lightskyblue-00;
  }
}

// Table status style
.statusblock {
  position: relative;
  padding-left: 15px;

  &.purpledot {
    &:before {
      background-color: $purple;
    }
  }

  &.pinkdot {
    &:before {
      background-color: $pink-f7;
    }
  }

  &.greendot {
    &:before {
      background-color: $green-39;
    }
  }

  &.darkpinkdot {
    &:before {
      background-color: $darkpink-d0;
    }
  }

  &.reddot {
    &:before {
      background-color: $red-fd;
    }
  }

  &.bluedot {
    &:before {
      background-color: $blue-4f;
    }
  }

  &.orangedot {
    &:before {
      background-color: $orange-e5;
    }
  }

  &.yellowdot {
    &:before {
      background-color: $yellow-cc;
    }
  }

  &:before {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -4px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    content: '';
  }
}

// Breadcrumb design style
.ant-breadcrumb {
  line-height: 13px;

  @include bp(xxs-min) {
    display: flex;
    flex-wrap: wrap;
  }

  > span {
    color: $grey-65;
    font-size: 11px;
    line-height: 13px;
    font-family: Graphik-regular;
    cursor: pointer;
    transition: 0.3s ease;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;

    .cursor-pointer {
      &:hover {
        text-decoration: underline;
      }
    }

    .ant-breadcrumb-link {
      color: $blue-2a;
      font-family: Graphik-medium;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// Upload block style
.uploadblock {
  .ant-form-item-control {
    border: 1px solid $grey-dd;
    border-radius: 4px;
    padding: 0 0 0 5px;

    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-end;
      position: relative;

      .ant-upload {
        // background-color: $green-53;
        color: $white;
        padding: 4px;
        border-radius: 4px;
      }

      .ant-upload-list {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

// Tooltip design style
.ant-tooltip {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      background-color: $blue-2a;
      border: $blue-2a;
      color: $white;
      font-size: 12px;
      font-family: Graphik-medium;

      a {
        display: flex;
        align-items: center;
        font-size: 14px;

        .menu {
          display: flex;
          align-items: center;
        }

        svg {
          width: 21px;
          margin-right: 10px;

          g,
          path {
            fill: $white;
          }
        }
      }
    }

    .ant-tooltip-arrow-content {
      // background-color: $skyblue-ea;
      background-color: $blue-2a;
    }
  }
}

// Panel design
.panel {
  &.purplepanel {
    .ant-collapse-header {
      background-color: $purple-ed;
      border-left: 3px solid $purple-98;
    }
  }

  &.pinkpanel {
    .ant-collapse-header {
      background-color: $pink-ff;
      border-left: 3px solid $pink-f7;
    }
  }

  &.greenpanel {
    .ant-collapse-header {
      background-color: $green-e7;
      border-left: 3px solid $green-39;
    }
  }

  &.darkpinkpanel {
    .ant-collapse-header {
      background-color: $pink-fe;
      border-left: 3px solid $pink-d0;
    }
  }

  &.redpanel {
    .ant-collapse-header {
      background-color: $red-ff;
      border-left: 3px solid $red-fd;
    }
  }

  &.bluepanel {
    .ant-collapse-header {
      background-color: $blue-e6;
      border-left: 3px solid $blue-4f;
    }
  }

  &.orangepanel {
    .ant-collapse-header {
      background-color: $orange-fff;
      border-left: 3px solid $orange-e5;
    }
  }

  &.yellowpanel {
    .ant-collapse-header {
      background-color: $yellow-ff;
      border-left: 3px solid $yellow-cc;
    }
  }
}

// Opportunity detail section style
.opportunitydetailblock {
  margin-bottom: 25px;

  h2 {
    margin-bottom: 5px;
  }

  &.activityblock {
    margin-bottom: 0;

    .ant-tabs-tabpane {
      min-height: 615px;
      overflow-y: scroll;

      @include bp(xxs-min) {
        min-height: inherit;
        overflow-y: hidden;
      }
    }

    .memberblock {
      margin-bottom: 20px;

      &.addcommentblock {
        textarea {
          padding: 12px;
          border: 1px solid $grey-dd;
          color: $grey-65;
          font-size: 14px;
        }
      }
    }
  }

  .proposaldocumentsblock {
    align-items: flex-start;

    @include bp(xxs-min) {
      flex-wrap: nowrap;
    }

    > .flexblock {
      @include bp(xxs-min) {
        margin-bottom: 15px;
      }
    }
  }

  .ant-table-column-sorters {
    padding: 9px 16px;
  }

  .description,
  textarea.ant-input {
    font-size: 15px;
  }

  textarea.ant-input {
    font-family: Graphik-regular;
  }

  .proposaldocument {
    margin-bottom: 22px;

    @include bp(xxs-min) {
      margin-bottom: 5px;
    }

    .documentblock {
      h4 {
        color: $blue-2a;
        font-size: 14px;
      }
    }
  }

  .actionbtn {
    @include bp(xxs-min) {
      margin-bottom: 15px;
    }
  }

  .description {
    white-space: pre-wrap;
  }
}

// Card design style
.cardopportunityblock {
  padding: 15px;
  border-radius: 12px;

  .smalltext {
    margin-left: -3px;
  }

  .flexblock {
    align-items: flex-start;
    flex-wrap: nowrap;

    h4 {
      color: $dark-blue;
      margin-right: 10px;
    }
  }

  .dateblock {
    p {
      padding-right: 10px;
      color: $grey-65;
      font-size: 12px;

      span {
        width: 100%;
        padding: 0 !important;
        color: $blue-2a;
        font-family: Graphik-medium;
      }
    }
  }
}

// Pagination design style
.ant-pagination {
  position: relative;
  margin: 0;
  padding: 10px;
  justify-content: flex-start;
  background-color: $grey-ec;
  font-size: 12px;

  &.ant-table-pagination {
    margin: 0;
  }

  .ant-pagination-total-text {
    position: absolute;
    right: 16px;
    margin: 0 8px;

    span {
      color: $blue-2a;
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    min-width: 19px;
    height: 26px;
    line-height: 26px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;

    .ant-pagination-item-link {
      border: 1px solid transparent;
      background-color: transparent;
      color: $grey-7e;

      .anticon-left,
      .anticon-right {
        svg {
          display: none;
        }
      }
    }
  }

  .ant-pagination-prev {
    background-image: url('../src/assets/images/icons-small-left-arrow.svg');
  }

  .ant-pagination-next {
    background-image: url('../src/assets/images/icons-small-right-arrow.svg');
  }

  .ant-pagination-item {
    min-width: 26px;
    height: 26px;
    border: none;
    color: $blue-2a;
    background-color: transparent;
    line-height: 26px;

    a {
      color: $blue-2a;
      line-height: 26px;
    }

    &.ant-pagination-item-active {
      border: none;

      a {
        font-family: 'Graphik-medium';
      }
    }
  }

  .ant-select-single {
    .ant-select-selector {
      height: 26px;
      padding: 0 30px 0 10px;
      border: 1px solid $grey-dd;

      .ant-select-selection-item {
        padding-right: 0;
        color: $blue-2a;
        font-size: 12px;
        font-family: 'Graphik-medium';
        line-height: 26px;
      }
    }

    .ant-select-arrow {
      right: 8px;
      background-image: url('../src/assets/images/icons-small-dropdown.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px;

      .anticon {
        display: none;
      }
    }

    &.ant-select-sm {
      .ant-select-selector {
        height: 26px;

        .ant-select-selection-item {
          line-height: 26px;
        }
      }
    }
  }

  &.mini {
    li {
      min-width: 24px;
      line-height: 26px;

      &.ant-pagination-options {
        height: 26px;
        margin-left: 10px;
      }
    }
  }
}

// Default Image style
.fakeImage {
  border-radius: 50%;
  background-color: $grey-65;
  border: 1px solid $white;
  color: $white;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.memberprofileblock {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  &.clientimg {
    .fakeProfile {
      margin-right: 10px;
    }
  }

  img {
    height: 30px;
    width: 30px;
    min-height: 30px;
    min-width: 30px;
    margin-left: -5px;
    border-radius: 50%;
    background-color: $grey-65;
    border: 1px solid $white;
    object-fit: cover;
  }

  span {
    color: $grey-65;
    line-height: 20px;
  }

  .fakeProfile {
    cursor: default;
    height: 27px;
    width: 27px;
    margin-left: -5px;
    font-size: 9px;
    font-family: Graphik-medium;
    line-height: 27px;
  }

  .addIcon {
    margin-left: -5px;
    height: 30px;
  }
}

.profileteamblock {
  .topsectionspacing {
    padding-bottom: 30px;
  }

  .ant-tabs {
    position: relative;
    top: -38px;

    @include bp(xs-min) {
      top: 10px;
    }

    .ant-tabs-nav {
      padding: 0 16px;
    }

    .ant-tabs-content-holder {
      margin-top: 15px;
      background-color: $white;

      .ant-tabs-content {
        border: 1px solid $grey-e1;
        border-radius: 4px;
      }
    }
  }
}

.teamsmainblock {
  // .justifyspacebetween {
  //   margin-bottom: 12px;
  // }
  .memmberprofile {
    position: relative;
    height: 53px;
    width: 53px;
    margin: 0 16px 0 0;

    img {
      height: 53px;
      width: 53px;
      object-fit: cover;
      object-position: center;
      width: 100%;
      border-radius: 50%;
    }

    p.fakeImage {
      height: 100%;
      width: 100%;
      line-height: 53px;
    }
  }
}

.membername {
  @include bp(xxs-min) {
    flex: 0 0 100%;
  }

  h4 {
    margin: 10px 0 4px;
    color: $blue-2a;
    font-size: 16px;
    font-family: Graphik-medium;
    line-height: 17px;

    span:first-child {
      position: relative;
      padding-left: 14px;
      color: $dark-blue;
      font-size: 12px;
      font-family: Graphik-regular;

      @include bp(xxs-min) {
        display: block;
      }

      &:before {
        position: absolute;
        top: 50%;
        left: 4px;
        height: 4px;
        width: 4px;
        margin-top: -2px;
        border-radius: 50%;
        background-color: $grey-b3;
        content: '';
      }

      &.active {
        color: $green-53;
      }
    }

    span:last-child {
      margin-left: 10px;
      font-family: Graphik-regular;
      font-size: 12px;
    }
  }

  .mailid {
    color: $grey-65;
    font-size: 13px;
    font-family: Graphik-regular;
  }
}

.membersearchblock {
  display: flex;
  align-items: center;

  h3 {
    margin: 0 15px 0 0;
    font-size: 18px;
  }

  .search {
    align-self: flex-end;
  }

  .ant-input-affix-wrapper {
    width: 311px;
    background-color: $grey-f2;
    border: none;
    padding: 7px 12px;

    input[type='text'] {
      background-color: $grey-f2;
      font-size: 16px;
      font-family: Graphik-regular;
    }
  }
}

.user-profile {
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.addlinkblock {
  align-items: flex-end;

  .ant-form {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    margin-bottom: 20px;

    @include bp(xxs-min) {
      flex-wrap: wrap;
    }

    .ant-form-item {
      margin-bottom: 0 !important;
      width: calc(100% - 90px);

      @include bp(xxs-min) {
        width: 100%;
        margin-bottom: 10px !important;
      }
    }
  }
}

// Popover style
.ant-popover {
  .ant-popover-content {
    .ant-popover-arrow {
      border-color: $skyblue-ea;
    }

    .successratio {
      z-index: 4;
      padding: 5px 20px;
      background-color: $skyblue-ea;
      border: 1px solid $skyblue-bc;
      box-shadow: 0 -2px 13px 0 $white-022;
      color: $blue-2a;
      font-size: 12px;
      font-family: Graphik-medium;

      span {
        color: $blue-2a;
        font-size: 12px;
        font-family: Graphik-regular;
      }
    }
  }
}

.successratiotext {
  color: $blue-2a;
  font-size: 12px;
  line-height: 25px;
  font-family: Graphik-medium;

  span {
    padding: 0 5px;
    font-size: 11px;
    font-family: Graphik-regular;
  }
}

// Accordian style
.ant-collapse {
  .ant-collapse-item {
    .ant-collapse-header {
      padding: 3px 16px 2px 40px;
      color: $dark-navy-blue;
      font-size: 13px;
      font-family: Graphik-medium;
      line-height: 35px;
      border-radius: 0;

      .anticon {
        &:after {
          position: absolute;
          left: -2px;
          top: 10px;
          display: inline-block;
          height: 15px;
          width: 15px;
          background-image: url('../src/assets/images/anticonarrow.svg');
          font-size: 20px;
          transition: transform 0.24s;
          -webkit-transition: transform 0.24s;
          -moz-transition: transform 0.24s;
          content: '';
        }
      }
    }

    &.ant-collapse-item-active {
      .ant-collapse-header {
        .anticon {
          &:after {
            transform: rotate(90deg);
          }
        }
      }
    }

    .ant-collapse-extra {
      width: 30px;
      height: 30px;
      border: 1px solid $grey-dd;
      border-radius: 4px;
      background-color: $white;
      text-align: center;
      line-height: 30px;
      font-family: Graphik-regular;
    }
  }
}

.memberblock {
  @include bp(xs-min) {
    margin-bottom: 10px;
    flex: 0 0 100%;
    text-align: center;
  }

  .memmberprofile {
    min-width: 32px;
    width: 32px;
    height: 32px;
    margin-right: 12px;

    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }

    .fakeImage {
      height: 100%;
      width: 100%;
      line-height: 32px;
      font-size: 11px;
    }
  }

  .documentblock {
    width: 100%;

    @include bp(xs-min) {
      text-align: left;
    }

    .attachmentbox {
      display: flex;

      .alltachment {
        width: 136px;
        height: 106px;
        border: 1px solid $dark-blue;
        border-radius: 4px;
        margin: 0 12px 12px 0;
        padding: 10px;
        font-size: 12px;
        position: relative;
        background-size: cover;

        .top {
          position: absolute;
          top: 10px;
          left: 10px;
          right: 25px;
          overflow: hidden;

          p {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            margin: 0;
            color: $white;
            font-weight: 500;
          }
        }

        .layer {
          background-color: rgba(70, 77, 105, 0.57);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        .footer {
          color: $white;
          position: absolute;
          padding: 0 10px;
          left: 0;
          bottom: 0;
          right: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            color: $white;
          }

          .downloadIcon {
            cursor: pointer;
          }
        }

        .delete {
          position: absolute;
          top: 4px;
          right: 6px;
          color: $white;

          svg {
            font-size: 13px;
          }
        }
      }
    }

    p {
      margin: 12px 0;
      font-size: 13px;
    }

    ul,
    ol {
      margin-bottom: 15px;
      padding-left: 40px;
    }
  }
}

.editfield {
  input[type='text'],
  textarea.ant-input {
    border: none;
    background-color: $grey-f2;
  }

  &.ant-form-item-hidden {
    background-color: $white;
  }
}

// RICHTEXT EDITOR DESIGN
.userinfoblock {
  h4 {
    position: relative;
    font-size: 14px;
    // &:hover {
    //   .emojihoverblock {
    //     display: block;
    //   }
    // }
    // .emojihoverblock {
    // position: absolute;
    // z-index: 2;
    // bottom: 12px;
    // display: none;
    // .emoji-mart-bar {
    //   display: none;
    // }
    // ul {
    //   display: flex;
    //   background-color: $white;
    //   border: 1px solid $grey-eb;
    //   li {
    //     position: relative;
    //     padding: 6px 8px 5px;
    //     &.leftborderline {
    //       position: relative;
    //       &::before {
    //         position: absolute;
    //         left: 0;
    //         top: 8px;
    //         width: 1px;
    //         height: 20px;
    //         background-color: $grey-eb;
    //         content: '';
    //       }
    //     }
    //   }
    // }
    // }
  }

  .RichTextEditor__root___2QXK- {
    .EditorToolbar__root___3_Aqz {
      padding: 5px 0 0px;
      border-bottom: 1px solid $grey-f2;

      .ButtonGroup__root___3lEAn {
        margin: 0 5px 0px 0;

        .ButtonWrap__root___1EO_R {
          button {
            height: 30px;
            background: transparent;
            border: none;

            .IconButton__icon___3YgOS,
            span {
              width: 20px;
              background-size: 15px;
            }
          }
        }
      }

      .Dropdown__root___3ALmx {
        select {
          height: auto;
          border: 1px solid $grey-f2;
          font-size: 10px;
        }
      }
    }

    .RichTextEditor__editor___1QqIU {
      min-height: 76px;
    }
  }
}

// Edit field block
.detailblock {
  padding: 0 15px;

  ul {
    padding: 0;

    li {
      display: flex;
      align-items: flex-start;
      padding: 15px 0;

      @include bp(sm-min) {
        padding: 10px 0;
      }

      @include bp(sm-min) {
        flex-wrap: wrap;
      }

      .ant-form-item {
        margin-bottom: 0 !important;

        .ant-input,
        .ant-picker,
        .ant-select {
          border: 1px solid $green-53;
          border-radius: 4px;

          &.ant-picker-focused,
          &.ant-select-focused {
            box-shadow: none;
            border: 1px solid $green-53;
          }

          .ant-select-selector {
            border: none;
          }
        }

        .ant-select {
          min-width: 180px;
        }
      }

      span {
        color: $grey-4a;
        font-size: 15px;
        font-family: Graphik-regular;
        line-height: initial;
        text-transform: capitalize;
        word-break: break-all;

        @include bp(sm-min) {
          padding: 10px 0;
        }

        @include bp(xs-min) {
          padding: 5px 0;
        }

        .documentlink {
          font-size: 15px;
          word-break: break-all;
        }

        &.titledetail {
          min-width: 200px;
          max-width: 200px;
          color: $dark-blue;
          font-family: Graphik-medium;

          @include bp(xl-min) {
            min-width: 160px;
            max-width: 160px;
          }

          @include bp(lg-min) {
            min-width: 200px;
            max-width: 200px;
          }

          @include bp(xxs-min) {
            min-width: 100%;
          }
        }

        .show-more-link {
          color: $green-53;
          font-family: Graphik-regular;
        }
      }

      .ant-btn {
        &.noTheme {
          padding-left: 10px;
          font-size: 12px;

          svg {
            width: 12px;
          }
        }

        &.addIcon {
          padding-left: 0;

          svg {
            width: 30px;
          }
        }
      }

      .memberprofileblock {
        .addIcon {
          padding-left: 0;
        }
      }
    }
  }
}

.updateinfo {
  p {
    color: $grey-a0;
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
  }
}

// Comment section design style
.comments {
  display: flex;
  flex-direction: column-reverse;
}

.memberblock {
  &.baselineprofile {
    // align-items: baseline;
    align-items: flex-start;

    .membername {
      h4 {
        margin: 10px 0 4px;

        @include bp(xs-min) {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }
  }
}

.comment-item {
  &:hover {
    .emojihoverblock {
      display: block;
      position: absolute;
    }
  }

  li {
    word-break: break-all;
  }

  .emojihoverblock {
    position: absolute;
    z-index: 2;
    bottom: 12px;
    display: none;

    .emoji-mart-bar {
      display: none;
    }
  }
}

.minimal-divider {
  margin: 0px !important;
}

.activity-height {
  height: calc(100vh - 345px);
  overflow: auto;

  @include bp(xxs-min) {
    height: auto;
    overflow: visible;
  }
}

.ant-modal-wrap {
  &.modalTitleCenter {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-close {
          .ant-modal-close-x {
            height: 73px;

            @media #{$PhoneL} {
              height: 58px;
            }
          }
        }

        .ant-modal-header {
          @include space(padding, 16px, 56px, 16px, 56px);

          .ant-modal-title {
            text-align: center;
            @include text($dark, 22px, 500);

            @media #{$PhoneL} {
              @include text($dark, 18px, 500);
              line-height: 1.4;
            }
          }
        }
      }
    }
  }

  .ant-modal {
    @include space(padding, 20px, 0, 20px, 0);

    @media #{$IpadS} {
      top: 10px;
    }

    .ant-modal-content {
      .ant-modal-header {
        @include space(padding, 14px, 22px, 14px, 22px);

        .ant-modal-title {
          font-size: 20px;
          @include text($dark, 18px, 500);
          line-height: 1.83;
        }
      }

      .ant-modal-close {
        right: 0;

        .ant-modal-close-x {
          width: 61px;
          height: 61px;

          @include d-flex {
            align-items: checkAlign(c);
          }
        }
      }

      .ant-modal-body {
        // max-height: calc(100vh - 260px);
        // overflow: auto;
        // @media #{$IpadS} {
        //   max-height: calc(100vh - 180px);
        // }
      }
    }
  }

  &.postContentModal {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          @include space(padding, 16px, 20px, 16px, 20px);

          @media #{$PhoneL} {
            @include space(padding, 15px, 10px, 15px, 10px);
          }

          .postHead {
            @include d-flex {
              align-items: checkAlign(c);
              // justify-content: getJustify(sb);
            }

            @include space(margin, 0, 0, 7px, 0);

            @media #{$PhoneL} {
              flex-direction: column;
              align-items: flex-start;
            }

            &__left {
              width: calc(100% - 140px);
              max-width: calc(100% - 140px);
              flex: 1 1 auto;

              @include d-flex {
                align-items: checkAlign(c);
              }

              @media #{$PhoneL} {
                width: 100%;
                max-width: 100%;
                @include space(margin, 0, 0, 10px, 0);
              }

              .thumb {
                flex: 0 0 40px;
                @include size(40px, 40px);
                border-radius: 50%;
                overflow: hidden;

                @media #{$PhoneL} {
                  flex: 0 0 30px;
                  @include size(30px, 30px);
                }

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .name {
                flex: 1 1 auto;
                width: 200px;
                max-width: 200px;
                @include text($dark-black, 16px, 500);
                @include space(margin, 0, 0, 0, 0);
                @include space(padding, 0, 0, 0, 12px);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                @media #{$PhoneL} {
                  width: calc(100% - 30px);
                  max-width: calc(100% - 30px);
                  @include font(14px);
                }
              }
            }

            &__right {
              flex: 0 0 140px;

              @include d-flex {
                align-items: checkAlign(c);
                justify-content: getJustify(fe);
              }

              @media #{$PhoneL} {
                flex: 1 1 auto;
                justify-content: getJustify(fs);
              }

              .post-type {
                @include space(margin, 0, 0, 0, 10px);

                &:first-child {
                  @include space(margin, 0, 0, 0, 0);
                }

                .ant-select-selector {
                  border-radius: 4px;
                  border: solid 1px $white-three;
                  background-color: $white-five;
                  @include space(padding, 5px, 10px, 5px, 10px);
                  @include size(40px, 140px);
                  @include text($dark-black, 14px, 400);

                  @include d-flex {
                    align-items: checkAlign(c);
                    justify-content: getJustify(sb);
                    flex-direction: row-reverse;
                  }

                  @media #{$PhoneL} {
                    @include size(30px, 120px);
                    line-height: 30px;
                    background-size: 18px;
                    background-position: center right 5px;
                  }

                  .ant-select-selection-placeholder,
                  .ant-select-selection-item {
                    @media #{$PhoneL} {
                      line-height: 30px;
                    }
                  }
                  .ant-select-selection-item {
                    font-family: 'Graphik-regular';
                  }
                }
              }
            }
          }

          .commentInput {
            color: $green-53;
            border: 0px;
            resize: none;
            @include placeholder($cool-grey-two);
            @include space(padding, 0, 0, 0, 0);
            @include space(margin, 10px, 0, 15px, 0);

            &:hover,
            &:focus {
              box-shadow: inherit;
              border: 0px;
            }
          }
        }

        .ant-modal-footer {
          text-align: left;

          @include d-flex {
            align-items: checkAlign(c);
            justify-content: getJustify(fe);
          }

          &__left {
            @include d-flex {
              align-items: checkAlign(c);
            }

            .ant-btn {
              @include space(margin, 0, 20px, 0, 0);

              .anticon {
                font-size: 20px;

                @include d-flex {
                  align-items: checkAlign(c);
                }
              }
            }
          }

          &__right {
            @include space(margin, 0, 0, 0, auto);
          }
        }
      }
    }
  }
}

.searchMenu {
  border: solid 1px $light-grey-two;
  height: 30px;
  border-radius: 30px;
  line-height: 22px;
  @include space(padding, 4px, 30px, 4px, 14px);
  @include space(margin, 3px, 8px, 3px, 0);
  @include font(13px);

  @include d-flex {
    align-items: checkAlign(c);
  }

  position: relative;

  @media #{$PhoneS} {
    @include space(padding, 4px, 30px, 4px, 9px);
    @include space(margin, 3px, 5px, 3px, 0);

    .ant-dropdown-link {
      .anticon {
        @include space(margin, 0, 0, 0, 8px);
      }
    }
  }

  .ant-dropdown-link {
    border: none;
    padding: 0;
    width: auto;
    height: auto;

    &[disabled] {
      background-color: transparent;
      color: $cool-grey-two;
      cursor: not-allowed;
    }

    font-weight: 400;
    position: static;
    color: $slate-grey-six;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .anticon {
      position: absolute;
      right: 10px;
      color: $charcoal-grey-four;
      @include d-flex();

      &-checkfill {
        font-size: 20px;
        right: 5px;
      }
    }
  }
}

.communitites--cards {
  padding: 20px;
  .CommunitiesListCardcontainer {
    border-color: $pale-grey-five;
    .groupinner {
      @include space(padding, 0, 10px, 0, 10px);
      .Eventrow {
        @include d-flex {
          align-items: checkAlign(c);
          flex-wrap: wrap;
        }
        &__col {
          flex: 1 1 auto;
          @include space(padding, 0, 10px, 0, 0);
        }
      }
      .group--username {
        word-break: break-all;
      }
      .group--tag {
        margin-left: 10px;
      }
    }
    .ant-card-cover {
      height: 125px;
      > img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
      .EventThumbSlider {
        .feedPostImg {
          > img,
          video,
          .DocTypeCardStyle {
            object-fit: cover;
            height: 125px;
            width: 100%;
          }
        }
        .slick-dots {
          position: absolute;
          bottom: 0;
          background-color: rgba($color: $dark, $alpha: 0.3);
          @include space(margin, 0, 0, 0, 0);
          @include space(padding, 4px, 4px, 4px, 4px);
          li {
            width: 8px;
            height: 8px;
            &.slick-active {
              > button {
                background-color: $primaryColor;
              }
            }
            > button {
              background-color: $pinkish-grey;
              width: 8px;
              height: 8px;
              opacity: 1;
              border-radius: 50%;
            }
          }
        }
        .slick-arrow {
          display: none !important;
        }
      }
    }
    h2 {
      @include headline($dark-blue, 15px, 0, 500);
      @include clamp(1);
      font-family: 'Graphik-Medium';
    }
    p {
      display: flex;
      margin: 5px 0 3px;
      color: $dark-blue;
      font-size: 12px;
      line-height: 17px;
      span {
        padding-left: 5px;
      }
      img {
        height: 18px;
        width: 18px;
        border-radius: 50%;
        margin-right: 9px;
      }
      &.eventusername {
        padding: 10px 0 12px;
      }
    }
    .ant-card-body {
      @include space(padding, 8px, 0, 0, 0);
    }
    .groupdetail {
      @include text($dark-blue, 14px, 400);
      @include space(margin, 0, 0, 10px, 0);
      @include d-flex {
        align-items: checkAlign(c);
        flex-wrap: wrap;
      }
      .info {
        @include space(margin, 0, 6px, 0, 0);
        display: flex;
        align-items: center;
        color: $dark-blue;
        font-size: 14px;
        .anticon {
          @include font(20px);
          height: 20px;
          @include space(margin, 0, 5px, 0, 0);
        }
      }
      .UserThumbListStyle {
        .thumbItem {
          @include size(20px, 20px);
          flex: 0 0 20px;
          max-width: 20px;
          border-width: 1px;
          @include font(8px);
        }
      }
    }
    .GroupCardAction {
      @include d-flex();
      border-top: 1px solid $pale-grey-five;
      .CardAction {
        &__item {
          @include space(padding, 7px, 0, 6px, 0);
          flex: 1 1 auto;
          border-left: 1px solid $pale-grey-five;
          @include d-flex {
            align-items: checkAlign(c);
            justify-content: getJustify(c);
          }
          @media #{$PhoneL} {
            @include space(padding, 5px, 0, 5px, 0);
          }
          &:first-child {
            border-left: 0;
          }
        }
      }
      .ant-btn {
        @include text($black-two, 12px, 400);
        @include space(padding, 1px, 5px, 1px, 5px);
        @include d-flex {
          align-items: checkAlign(c);
          justify-content: getJustify(c);
        }
        @media #{$PhoneL} {
          @include text($black-two, 12px, 400);
        }
        &:hover {
          color: $green-53;
        }
        span {
          @include space(margin, 0, 0, 0, 0);
        }
        .anticon {
          @include space(margin, 0, 8px, 0, 0);
          font-size: 24px;
          color: $green-53;
          @include d-flex {
            align-items: checkAlign(c);
            justify-content: getJustify(c);
          }
          @media #{$PhoneL} {
            @include space(margin, 0, 4px, 0, 0);
            width: 16px;
            font-size: 16px;
          }
          &.ant-edit {
            svg {
              font-size: 16px;
            }
          }
          &.anticon-closeCricle {
            svg {
              font-size: 16px;
            }
          }
          &.ant-share {
            svg {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}

.confirmModal {
  &.ant-modal {
    .ant-modal-content {
      .ant-modal-body {
        text-align: center;
        @include space(padding, 30px, 15px, 40px, 15px);

        .ant-modal-confirm-body {
          &-wrapper {
            max-width: 350px;
            margin: 0 auto;
          }

          .ant-modal-confirm-title {
            @include text($dark, 18px, 500);
            font-family: Graphik-medium;
            line-height: 33px;
          }

          .ant-modal-confirm-content {
            @include text($black-two, 15px, 400);
            @include space(margin, 6px, 0, 0, 0);
            line-height: 22px;
          }
        }

        .ant-modal-confirm-btns {
          width: 100%;
          text-align: center;

          @include d-flex {
            align-items: checkAlign(c);
            justify-content: getJustify(c);
          }
          @media #{$PhoneL} {
            @include space(margin, 0, 4px, 0, 0);
            width: 16px;
            font-size: 16px;
          }
          &.ant-edit {
            svg {
              font-size: 16px;
            }
          }
          &.anticon-closeCricle {
            svg {
              font-size: 16px;
            }
          }
          &.ant-share {
            svg {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}

.discardConfirmModal.ant-modal .ant-modal-content .ant-modal-body {
  .ant-modal-confirm-body .ant-modal-confirm-title {
    color: $black;
    font-size: 18px;
    font-weight: 500;
    line-height: 33px;
    text-align: center;
  }
  .ant-modal-confirm-btns {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-modal-confirm-btns .ant-btn {
    margin: 0 5px 0 5px;
    width: calc(50% - 10px);
    max-width: calc(50% - 10px);
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - 10px);
    height: 53px;
    font-size: 18px;
    font-weight: 500;
  }
  .ant-modal-confirm-body .ant-modal-confirm-content {
    color: #222222;
    font-size: 15px;
    font-weight: 400;
    margin: 6px 0 0 0;
    line-height: 22px;
    text-align: center;
  }
}

//round checkbox css start
.roundcheckbox {
  &.ant-checkbox-wrapper {
    width: 100%;
    .ant-checkbox {
      border-radius: 50%;
      overflow: hidden;
      border: none;
      &.ant-checkbox-checked {
        &::after {
          border: none;
        }
        .ant-checkbox-inner {
          &:after {
            opacity: 1;
          }
        }
      }
    }
    .ant-checkbox-inner {
      border: none;
      width: 21px;
      height: 21px;
      border-radius: 50%;
      &:after {
        border: none;
        transform: none;
        background: url('./assets/images/check-icon.svg') no-repeat center
          center;
        background-size: cover;
        height: 21px;
        width: 21px;
        top: 0;
        left: 0;
        content: '';
        opacity: 0.2;
        border-radius: 50%;
      }
    }
  }
}
//round checkbox css end

.ant-btn-pastelred {
  color: $pastel-red;
}

.ant-btn-green {
  color: $green-53;
}

.ant-btn-inline {
  padding: 0;
  width: auto;
  height: auto;
  border: 0px;
}

// Directory Height Styles
.ContractorList {
  > *:first-child {
    min-height: $directory-max;
  }
  .ant-row {
    max-height: $directory-max;
    > *:first-child {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .ContractorcardStyles {
      flex: 1;
      .ant-row {
        max-height: $directory-max-inner;
        overflow: auto;
      }
    }
  }
}

.mention {
  display: inline-block;
  background: $primaryColor;
  border-radius: 4px;
  padding: 3px 5px;
  color: $white;
  span {
    display: initial !important;
  }
  a {
    color: $white !important;
    text-decoration: none !important;
  }
}
