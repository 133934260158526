@import '../../../variables.scss';

.chromeBar {
  display: flex;
  height: 45px;
  padding: 0px 12px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 28.26%,
      rgba(255, 255, 255, 0.12) 82%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) -92.12%,
      rgba(0, 0, 0, 0.04) 98.99%
    ),
    #53c3bd;

  border: none !important;

  .content {
    display: flex;
    align-items: center;
    gap: 12px;

    .text {
      color: var(--White, var(--Backgrounds-Primary, #fff));
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      .chromeLink {
        color: white;
        text-decoration-line: underline;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  // Hide this when screen is lower than large
  @media (max-width: 1024px) {
    display: none;
  }
}
