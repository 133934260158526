@import '../../variables.scss';
.ant-layout-header {
  position: fixed;
  height: auto;
  width: 100%;
  background-color: $white;
  padding: 0;
  line-height: initial;
  z-index: 999;
  // padding: 0 155px;
  border-bottom: 1px solid $grey-d9;
  @include bp(md-min) {
    padding: 0;
  }
  .TopheaderStyles {
    margin: 0 auto;
    width: 1127px;
    @include bp(lg-min) {
      width: 100%;
    }
    .topheadermainblock {
      padding: 0px 15px;
      @include bp(sm-min) {
        padding: 9px 15px;
      }
      @include d-flex {
        align-items: checkAlign(c);
      }
      // background-color: $white;
      .sitelogoblock {
        margin-right: 15px;
        // padding: 12px 0px;
        @include d-flex {
          align-items: checkAlign(c);
        }
        // width: 290px;
        @include bp(sm-min) {
          // width: 130px;
        }
        @include bp(xxs-min) {
          width: 100%;
          text-align: center;
        }
        .websitelogo {
          max-width: 117px;
        }
        .link {
          display: none;
        }
        .always_visible {
          display: block;
        }
      }
      .site_search {
        flex: 1 1 auto;
        margin-right: 15px;
        @include bp(xs-min) {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 10;
          background-color: $white;
          @include space(padding, 15px, 15px, 15px, 15px);
          @include d-flex {
            align-items: checkAlign(c);
            justify-content: getJustify(fs);
          }
          transform: scaleX(0);
          transform-origin: right;
          transition: all 0.3s ease-in-out;
          &.open {
            transform: scaleX(1);
          }
        }
        .btn-back {
          @include d-flex {
            align-items: checkAlign(c);
            justify-content: getJustify(c);
          }
          display: none;
          @include bp(xs-min) {
            display: flex;
          }
          @include bp(sm-min) {
            @include space(margin, 0, 10px, 0, 0);
            flex: 0 0 32px;
          }
          .anticon {
            @include d-flex();
            font-size: 22px;
          }
        }
        .ant-select-selector {
          .ant-select-selection-search {
            -webkit-transition: width 1s ease-in-out;
            -moz-transition: width 1s ease-in-out;
            -o-transition: width 1s ease-in-out;
            transition: width 1s ease-in-out;
          }
        }
        .ant-input-affix-wrapper {
          background-color: $palegrey;
          border-radius: 4px;
          border: 0px;
          height: 40px;
          @include space(padding, 7px, 15px, 7px, 12px);
          @media #{$IpadS} {
            flex: 1 1 auto;
            width: calc(100% - 10px);
            max-width: calc(100% - 10px);
          }
          .ant-input {
            background-color: $palegrey;
            @include text($slate-grey-two, 18px, 400);
            &::-webkit-input-placeholder {
              color: $slate-grey-two;
            }
            &::-moz-placeholder {
              color: $slate-grey-two;
            }
            &:-ms-input-placeholder {
              color: $slate-grey-two;
            }
            &:-moz-placeholder {
              color: $slate-grey-two;
            }
          }
          .ant-input-prefix {
            @include space(margin, 0px, 10px, 0px, 0);
            color: $slate-grey-two;
          }
        }
        // padding: 12px 0px;
        @include d-flex {
          align-items: checkAlign(c);
        }
        // margin: 0 10px;
        .ant-input-affix-wrapper {
          background-color: $grey-f2;
          border: none;
          padding: 5px 12px;
          max-width: 760px;
          width: 300px;
          &-focused {
            width: 100%;
          }
          @include bp(xxs-min) {
            margin: 15px 0;
          }
          &:focus {
            box-shadow: none;
          }
          input[type='text'] {
            color: $grey-65;
            background-color: transparent;
            font-size: 18px;
            font-weight: 300;
            @include bp(sm-min) {
              font-size: 16px;
            }
          }
          > input.ant-input {
            padding: 0;
            padding-left: 8px;
            background: transparent;
            font-size: 18px;
            font-weight: 300;
            font-family: Graphik-regular;
          }
        }
      }
      .headerrightblock {
        // padding: 12px 0px;
        .btn-search {
          height: inherit;
          display: none;
          justify-content: center;
          align-items: center;
          width: 76px;
          padding: 0;
          @include bp(xs-min) {
            display: flex;
          }
        }
        @include d-flex {
          flex: 1;
          justify-content: getJustify(fe);
        }
        @include bp(xxs-min) {
          justify-content: center;
        }
        @include bp(sm-min) {
          width: calc(100% - 130px);
        }
        @include bp(xxs-min) {
          width: 100%;
        }
        .header_navigation {
          @include bp(sm-min) {
            display: none;
          }
          @include d-flex {
            align-items: checkAlign(c);
          }
          .header_auth {
            padding: 12px 0px;
            .ant-btn {
              margin-right: 10px;

              &:last-child {
                margin-right: 0;
              }
            }
          }
          ul {
            list-style: none;
            margin: 0;
            padding: 0;
            @include d-flex {
              align-items: checkAlign(c);
              justify-content: getJustify(sb);
            }
            li {
              margin-right: 8px;
              .ant-badge {
                sup {
                  margin-left: -20px;
                  margin-top: 6px;

                  p {
                    color: $white;
                    font-size: 12px;
                  }
                }
              }
              a {
                display: block;
                padding: 12px 10px;
                text-align: center;
                &.active {
                  border-bottom: 1px solid $primaryColor;
                }
              }
              svg {
                width: 22px;
                height: 22px;
                &,
                g,
                path {
                  fill: $primaryColor;
                }
              }
              span {
                text-align: center;
                display: block;
                white-space: nowrap;
                font-size: 13px;
              }
              &:last-child {
                margin: 0;
              }
            }
          }
        }
        .headeruserblock {
          margin-left: 10px;
          // margin-right: 10px;
          @include d-flex {
            align-items: checkAlign(c);
            justify-content: getJustify(fe);
          }
          .messages-icon {
            display: block;

            svg {
              width: 23px;
              height: 23px;
            }
          }
          @include bp(xxs-min) {
            flex-wrap: wrap;
            text-align: center;
          }
          .ant-input-affix-wrapper {
            background-color: $grey-f2;
            border: none;
            padding: 5px 12px;
            max-width: 760px;
            width: 100%;
            @include bp(xxs-min) {
              margin: 15px 0;
            }
            &:focus {
              box-shadow: none;
            }
            input[type='text'] {
              color: $grey-65;
              background-color: transparent;
              font-size: 18px;
              font-weight: 300;
              @include bp(sm-min) {
                font-size: 16px;
              }
            }
          }
          .userprofileblock {
            .notificationicon,
            .userprofile {
              border: none;
              background: transparent;
              margin: 0px 16px;
              @include bp(xxs-min) {
                margin: 0 15px;
              }
              .UserImage {
                height: 36px;
                width: 36px;
              }
            }
          }
          .userprofileblock {
            @include bp(xxs-min) {
              width: 100%;
            }
          }
        }
        .headermenutrigger {
          border-left: 1px solid $grey-d9;
          padding-left: 12px;
          @include d-flex {
            align-items: checkAlign(c);
          }
          .menu_trigger {
            text-align: center;
            cursor: pointer;
            svg {
              width: 22px;
              height: 22px;
            }

            span {
              text-align: center;
              display: block;
              white-space: nowrap;
              font-size: 13px;
            }
          }
        }
        .btnblock {
          .ant-btn {
            font-size: 18px;
            &.ant-btn-link {
              color: $grey-65;
            }
          }
        }
      }
    }
  }
}

.ant-popover {
  .ant-popover-content {
    .ant-popover-arrow {
      @include bp(xxs-min) {
        opacity: 0;
      }
    }
  }
}

.logoutblock {
  padding: 13px 16px;
  .memberblock {
    justify-content: flex-start;
    align-items: flex-start;
    @include bp(xxs-min) {
      margin-bottom: 0;
      text-align: left;
    }
    .memmberprofile {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      margin-right: 12px;
      img {
        width: 100%;
      }
    }
    .membername {
      @include bp(xxs-min) {
        flex: auto;
      }
    }
  }
  .secondarybtn {
    margin: 12px 0 15px;
  }
  .signupblock {
    border-top: 1px solid $grey-eb;
    padding-top: 5px;
    .ant-btn-link {
      padding-left: 0;
      font-size: 14px;
      font-family: 'Graphik-regular';
      color: $blue-2a;
      text-align: left;
    }
    .org-group {
      width: 100%;
      overflow-x: hidden;
      margin-bottom: 5px;
      .orgCompany {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 8px 10px;
        .orgCompanyAvatar {
          margin-right: 5px;
        }
      }
      .selected-company {
        background-color: $green-53;
      }
    }
  }
}
