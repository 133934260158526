@import '../../variables.scss';

.FooterStyles {
  .footermainblock {
    background-color: $grey-ee;
    .ant-menu {
      background-color: transparent;
      padding: 14px;
      flex-wrap: wrap;
      .ant-menu-item {
        position: relative;
        margin: 0;
        height: auto;
        color: $grey-65;
        padding: 5px 14px;
        line-height: 20px;
        &:first-child {
          &:before {
            display: none;
          }
        }
        &:before {
          position: absolute;
          top: 50%;
          left: 0;
          height: 4px;
          width: 4px;
          margin-top: -2px;
          border-radius: 50%;
          background-color: $grey-65;
          content: '';
        }
        a {
          color: $grey-65;
          font-size: 14px;
          font-family: Graphik-regular;
          line-height: 20px;
          &:hover {
            color: $green-53;
          }
        }
      }
    }
  }
}
