@import '../../../variables.scss';
.SectionLoaderStyle {
  width: 100%;
  height: 100%;
  background-color: rgba($color: $white, $alpha: 0.4);
  @include d-flex {
    align-items: checkAlign(c);
    justify-content: getJustify(c);
  }
  z-index: 999;
  .ant-spin {
    .anticon { 
      @include font(24px);
    }
    .ant-spin-text {
      @include space(margin, 10px, 0, 0, 0);
      @include font(16px);
    }
  }
}