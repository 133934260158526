@import '../../../variables.scss';

.user-sidebar-card {
  width: 250px;
  margin-bottom: 16px;
  .user-card {
    // border: solid 1px #dee2ea;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.104506);
    -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.104506);
    -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.104506);
    &__header {
      background-color: $primaryColor;
      height: 60px;
      border-radius: 4px 4px 0px 0px;
    }
    &__avatar {
      width: 60px;
      height: 60px;
      background-size: cover;
      background-position: center;
      background-color: #fff;
      border: 2px solid #fff;
      border-radius: 50%;
      margin: -30px auto;
    }
    &__name {
      margin-top: 40px;
      text-align: center;
      font-size: 15px;
      font-family: Graphik-Medium;
      color: $primaryColor;
    }
    &__company {
      text-align: center;
      font-size: 13px;
      font-family: Graphik-regular;
      color: $grey-7e;
    }
    &__jobtitle {
      text-align: center;
      font-size: 14px;
      font-family: Graphik-regular;
      color: $primaryColor;
    }
    &__info {
      padding: 18px 13px;
      .info-section {
        margin-bottom: 10px;
        &:last-child {
          margin: 0;
        }
        @include d-flex {
          align-items: checkAlign(c);
        }
        span:first-child {
          flex: 1;
          // font-family: Graphik-medium;
          color: $primaryColor;
        }
      }
    }
  }
}