@import "../../../variables.scss";
.GSSidebarStyles {
  width: 250px;
  max-width: 300px;
  .ant-menu {
    background-color: transparent;
    .ant-menu-item {
      @include text($dark, 16px, 400);
      line-height: 22px;
      height: auto;
      @include d-flex {
        align-items: checkAlign(c);
        justify-content: getJustify(fs);
      }
      @include space(padding, 9px, 25px, 9px, 25px);
      @include space(margin, 0, 0, 0, 0);
      &::after {
        right: inherit;
        left: 0;
      }
      &.ant-menu-item-selected {
        color: $primaryColor;
        font-weight: 500;
        background-color: transparent;
      }
      &:hover {
        color: $primaryColor;
        font-weight: 500;
      }
    }
  }
}
