@import '../../../variables.scss';

.QuickMenuStyles {
    margin-bottom: 16px;
    width: 250px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.104506);
    -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.104506);
    -moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.104506);
    padding: 15px;
    ul {
        list-style: none;

        li {
            margin-bottom: 19px;
            a {
                @include d-flex {
                    align-items: checkAlign(c);
                }
                &:hover > span {
                    color: $green-53;
                }
                &:hover > svg path {
                    fill: $green-53;
                }
            }

            &:last-child {
                margin-bottom: 0px;
            }

            svg {
                width: 27px;
                height: 27px;

                &,
                g,
                path {
                    fill: $primaryColor;
                }
            }
            span {
                flex: 1;
                margin-left: 10px;
                font-family: Graphik-regular;
            }

        }
    }
}