@import "../../../variables.scss";
.UserrowStyles {
  cursor: pointer;
  @media #{$PhoneL} {
    flex-direction: column;
    @include d-flex {
      align-items: checkAlign(fs);
    }
  }
  @include d-flex {
    align-items: checkAlign(c);
  }
  .user-info {
    @include d-flex {
      align-items: checkAlign(c);
    }
    @include space(padding, 10px, 5px, 10px, 5px);
    flex: 1 1 auto;
    @media #{$PhoneL} {
      @include size($width: 100%);
      @include space(margin, 0, 0, 10px, 0);
    }
  } 
  .user-image {
    @include size(53px, 53px);
    max-width: 53px;
    overflow: hidden;
    flex: 0 0 auto;
    img {
      @include img(cover);
      border-radius: 100px;
    }
  }
  .user-content {
    @include space(padding, 0, 12px, 0, 12px);
    flex: 1 1 auto;
  }
  .user-name {
    @include text($black-two, 16px, 500);
    @include space(margin, 0, 0, 0, 0);
    @include clamp(1);
    @media #{$PhoneL} {
      @include font(14px);
    }
  }
  .user-text {
    @include clamp(1);
  }
  .userinfo-row {
    flex: 1 1 auto;
    @include d-flex {
      align-items: checkAlign(c);
    }
    @media #{$PhoneL} {
      flex-direction: column;
      @include d-flex {
        align-items: checkAlign(fs);
      }
    }
  }
}
