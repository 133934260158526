@mixin clamp($line, $display: -webkit-box) {
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: $display;
}
@mixin font($font) {
  font-size: $font;
}
//mixin size
@mixin size($height: null, $width: null) {
  height: $height;
  width: $width;
}
//image mixin
@mixin img($type) {
  object-fit: $type;
  height: 100%;
  width: 100%;
}
//margin padding mixin
@mixin space($type, $top, $right, $bottom, $left) {
  @if $type == padding {
    padding: $top $right $bottom $left;
  } @else {
    margin: $top $right $bottom $left;
  }
}
//position mixin
@mixin position(
  $position: null,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null,
  $z: null
) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  z-index: $z;
}
//heading mixin start
@mixin headline($color, $size, $bottom, $weight) {
  color: $color;
  font-size: $size;
  margin-bottom: $bottom;
  font-weight: $weight;
}
//heading mixin end
//text mixin start
@mixin text($color, $size, $weight) {
  color: $color;
  font-size: $size;
  font-weight: $weight;
}
//text mixin end
//flex mixin start
// Get the value for justify-content
@function getJustify($arg) {
  $justify: (
    'sa': space-around,
    'sb': space-between,
    'se': space-evenly,
    'c': center,
    'fs': flex-start,
    'fe': flex-end,
  );

  @each $key, $value in $justify {
    @if ($key == $arg) {
      @return $value;
    }
  }
}
//Get the value for align-items
@function checkAlign($arg) {
  $align: (
    'b': baseline,
    's': stretch,
    'c': center,
    'fs': flex-start,
    'fe': flex-end,
  );

  @each $key, $value in $align {
    @if ($key == $arg) {
      @return $value;
    }
  }
}
@mixin d-flex() {
  display: flex;
  @content;
}

// device specific hide show content mixin
@mixin hide-for($device, $showsm) {
  @if $showsm {
    display: none;
    @media #{$device} {
      @include d-flex();
    }
  } @else {
    @include d-flex();
    @media #{$device} {
      display: none;
    }
  }
}
@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &::placeholder {
    color: $color;
  }
}

// Mixins
@mixin flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: flex;
}
