@import "../../../variables.scss";
.NoDataFoundStyle {
  @include d-flex() {
    align-items: center;
    justify-content: center;
  }
  width: 100%;
  height: 100%;
  background-color: $white;
  // border: 1px solid $pale-grey-five;
  // border-radius: 4px;
  @include space(padding, 100px, 15px, 100px, 15px);
  @include space(margin, 0, 0, 20px, 0);
  @media #{$PhoneL} {
    @include space(padding, 50px, 15px, 50px, 15px);
  }
  .noDataBox {
    text-align: center;
    &__img {
      @include space(padding, 0, 0, 16px, 0);
    }
    p {
      font-size: 18px;
      color: $battleship-grey-two;
    }
  }
  .pendingInvite {
    max-width: 343px;
    h1 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 0;
    }
    p {
      font-size: 16px;
    }
  }
  &.pending {
    border: none;
  }
  .feedback-link {
    a {
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
    }
  }
}
.NonotificationStyle {
  .noDataBox {
    @media #{$PhoneL} {
      h1 {
        font-size: 18px;
      }
      p {
        font-size: 14px;
      }
    }
  }
}
