@import '../../variables.scss';

.menu-drawer {
  .ant-drawer-header {
    padding: 18px;
    border-bottom: 1px solid $grey-a0;
    .ant-drawer-title {
      font-family: Graphik-Medium;
    }
  }
  .ant-drawer-body {
    padding: 18px;
    .menu-links {
      .menu-link {
        width: 100%;
        
        a {
          display: block;
          width: 100%;
          text-align: center;
          border: 1px solid $grey-a0;
          padding: 8px;
          border-radius: 5px;
          background-color: #fff;
          
          &:hover, &.active {
            background-color: $primaryColor;
            border: 1px solid $primaryColor;

            svg, g, path, span {
              color: #fff;
              fill: #fff;
            }
          }
        }
        svg {
          width: 22px;
          height: 22px;
          
          &, g, path {
            fill: $primaryColor;
          }
        }
        span {
          display: block;
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }
}