@import '../../variables.scss';
.ModalbiddecisionmatrixStyles {
  // your styles here
}
.ant-modal {
  &.biddecisionmatrixmodal {
    width: 860px !important;
    @include bp(md-min) {
      width: 700px !important;
    }
    @include bp(sm-min) {
      width: 520px !important;
      overflow: hidden;
    }
    .ant-modal-content {
      .ant-modal-header {
        border-bottom: none;
      }
      .ant-modal-body {
        padding: 0 0 20px;
        .biddecisionblock {
          h5 {
            display: flex;
            align-items: center;
            padding: 11px 22px;
            border: 1px solid $grey-dd;
            background-color: $grey-f2;
            color: $grey-4a;
            font-size: 13px;
            font-family: Graphik-medium;
            svg {
              display: none;
              @include bp(xs-min) {
                display: block;
              }
            }
          }
          .biddecisionmatrixbox {
            padding: 20px 55px;
            @include bp(sm-min) {
              padding: 20px;
            }
            .filterwrapper {
              display: flex;
              @include bp(xs-min) {
                display: none;
              }
              .filterinfo {
                padding-bottom: 10px;
                color: $grey-65;
                font-size: 13px;
                line-height: 18px;
              }
            }

            .sliderblock {
              position: relative;
              img {
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 9;
              }
              .ant-slider-step,
              .ant-slider-track {
                opacity: 0;
              }
              .ant-slider-handle {
                width: 11px;
                height: 24px;
                margin-top: -10px;
                background-color: $grey-d9;
                border: solid 1px $white;
                border-radius: 5px;
                box-shadow: none;
                z-index: 9;
              }
            }
            .filternumber {
              display: flex;
              align-items: center;
              justify-content: space-around;
              li {
                font-size: 14px;
                font-family: Graphik-medium;
                line-height: 25px;
                list-style-type: none;
              }
            }
          }
          .scorefactorblock {
            padding: 16px 22px;
            background-color: $grey-dd;
            @include bp(sm-min) {
              flex-wrap: wrap;
            }
            h4 {
              margin-right: 5px;
              margin-bottom: 0;
              color: $blue-2a;
              @include bp(sm-min) {
                margin: 5px 0;
              }
              .ant-btn {
                .noTheme {
                  cursor: pointer;
                }
              }
              svg {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}
.successratio {
  &.filtermobileinfo {
    display: none;
    @include bp(xs-min) {
      display: block;
    }
  }
}

.ant-popover {
  z-index: 1000;
}
